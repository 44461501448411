// set patient uuid in storage

import { jwtDecode } from 'jwt-decode';
import { localStorageGet, localStorageRemove, localStorageSet } from '@/helpers/localStorage';
import { AuthToken, DecodedAccessToken } from '../../../types/api/auth/AuthToken';

// For certain analytics and tracking purposes, we need to store the patient_uuid in local storage.
export const setPatientUuidInStorage = (tokenData: Pick<AuthToken, 'access_token'>) => {
    try {
        const accessToken: DecodedAccessToken = jwtDecode(tokenData.access_token);
        localStorageSet('patient_uuid', accessToken.patient_uuid);
    } catch (e) {
        console.error('Error decoding access token', e);
    }
};

// Get the patient_uuid from local storage
export const getPatientUuidFromStorage = () => localStorageGet('patient_uuid');

// Delete the patient_uuid from local storage
export const deletePatientUuidFromStorage = () => localStorageRemove('patient_uuid');
