import { affiliateInScope } from '../../../../../affiliate/helpers/affiliateInScope';

import ProductTypes from '../../../../../../types/api/products/ProductTypes';
import { Product } from '../../../../../../types/api/products/Product';

type GetTreatmentImage = (reference: number) => string;

export interface variants {
    variant_id: number;
    stock: number;
    parent_id: number;
}

export interface FormatProductsReturnType {
    name: string;
    id: number;
    category: string;
    variant: string;
    dimension2: 'Logged in' | 'Not Logged in';
    dimension3: ProductTypes;
    dimension4: 'Default Variant' | 'Not Default Variant';
    dimension15: string | null;
    quantity?: number | null;
    wasPrice?: number | null | string;
    nowPrice?: number | null | string;
    quantities?: { label: string; quantity: number; saving: string; price: string }[];
    variants?: variants[];
    stock?: number;
}

const formatProducts = (
    treatment: Product,
    isLoggedIn: boolean,
    affiliateCode: string,
    getTreatmentImage: GetTreatmentImage
): FormatProductsReturnType[] => {
    const { variants, condition_name: conditionName, name: treatmentname } = treatment;

    return variants.map((variant) => {
        const { default: defaultVariant, type, name, id, quantities } = variant; // eslint-disable-line object-curly-newline

        const isDefault = defaultVariant ? 'Default Variant' : 'Not Default Variant';
        const loggedIn = isLoggedIn ? 'Logged in' : 'Not Logged in';

        const returnData = {
            name: treatmentname,
            id,
            category: conditionName || 'General Health',
            image: getTreatmentImage ? getTreatmentImage(id) : null,
            variant: name,
            dimension2: loggedIn,
            dimension3: type.toUpperCase() as ProductTypes, // PRODUCT, PMED, ODONLY
            dimension4: isDefault,
            dimension15: affiliateInScope(id) ? affiliateCode : null, // If there is an affiliate code applied.
            stock: variant.stock,
        } as FormatProductsReturnType;

        if (quantities.length) {
            returnData.quantities = quantities.map((quantity) => ({
                label: quantity.label,
                quantity: quantity.quantity,
                price: ((quantity.price || 0) / 100).toFixed(2),
                saving: ((quantity.saving || 0) / 100).toFixed(2),
            }));
        }

        if (treatment.variants.length) {
            returnData.variants = treatment.variants.map((variant) => ({
                variant_id: variant.id,
                stock: variant.stock,
                parent_id: treatment.id,
            }));
        }

        return returnData;
    });
};

export default formatProducts;
