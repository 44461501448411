import { RedisTreatment } from "../../../../lib/redis/types/RedisTreatment";

export interface FormatSelectedConditionAnalytics {
    conditionName: string;
    conditionSlug: string;
    treatments: Array<RedisTreatment>;
}

/**
 * Formats the condition page data for google analytics
 * @param condition
 * @param relatedTreatments
 * @returns
 */
const formatSelectedConditionAnalytics = ({ conditionName, conditionSlug, treatments }: FormatSelectedConditionAnalytics) => ({
    category_page: `/${conditionSlug}`,
    items: treatments.map((treatment) => ({
        id: treatment.defaultVariant, // Id is either default variant or the current treatment id.
        name: treatment.title,
        category: conditionName,
    })),
});

export default formatSelectedConditionAnalytics;
