import get from "lodash.get";
import { jwtDecode } from "jwt-decode";
import { localStorageGet } from "../../../helpers/localStorage";
import { SESSION_TOKEN_LOCAL_STORAGE_KEY } from "../auth";

// Gets a fresh logged in state. Useful for absolute values
export const getLoginInfo = () => {
    const accessToken = get(localStorageGet(SESSION_TOKEN_LOCAL_STORAGE_KEY), 'access_token');

    return accessToken ? jwtDecode(accessToken) : null;
}
