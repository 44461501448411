import getDefaultVariant from '../../../../../../components/Treatment/TreatmentSelector/legacy/getDefaultVariant';
import NextDelivery from '../../../../../../types/api/delivery/NextDelivery';
import { Product } from '../../../../../../types/api/products/Product';

type getTreatmentImageType = (reference: number) => string;
type getTreatmentDescriptionType = (reference: number) => string;

interface FormatFreshDataLayer {
    treatment: string;
    desc: string;
    image: string;
    name: string;
    id: number;
    quantity: number | null;
    wasPrice?: string | null;
    nowPrice?: string | null;
    sku: number;
    stock: number;
    url: string;
    'next-delivery-date': string;
    'cutoff-time': string;
}

export type FormatFreshDataLayerReturnType = FormatFreshDataLayer | null;

const formatFreshDataLayer = (
    treatment: Product,
    deliveryData: NextDelivery,
    getTreatmentImage: getTreatmentImageType,
    getTreatmentDescription: getTreatmentDescriptionType
): FormatFreshDataLayerReturnType => {
    const defaultVariant = getDefaultVariant(treatment.variants);

    if (!defaultVariant) {
        return null;
    }

    const { id, name, quantities, stock } = defaultVariant; // eslint-disable-line object-curly-newline

    const nextDeliveryDate = deliveryData['next-delivery-date'];
    const cutOffTime = deliveryData['cutoff-time'];

    const variantData: FormatFreshDataLayer = {
        treatment: treatment.condition_name || 'General Health',
        desc: getTreatmentDescription(id),
        image: getTreatmentImage(id),
        name,
        id,
        quantity: null,
        wasPrice: null,
        nowPrice: null,
        sku: id,
        stock,
        url: window.location.href,
        'next-delivery-date': nextDeliveryDate,
        'cutoff-time': cutOffTime,
    };

    if (quantities.length) {
        const defaultQuantity = quantities.find((variant) => variant.default) || quantities[0];
        const price = ((defaultQuantity.price || defaultQuantity.unit_cost || 0) / 100).toFixed(2);
        variantData.quantity = defaultQuantity.quantity;
        variantData.wasPrice = price;
        variantData.nowPrice = price;
    }

    return variantData;
};

export default formatFreshDataLayer;
