import createAuthRefreshInterceptor from 'axios-auth-refresh';
import get from 'lodash.get';
import { AUTH_REFRESH_API_PATH } from '../../../data/services/auth.service';
import auth from '../../../modules/auth/auth';

export const shouldIntercept401Refresh = (status, url) => status === 401 && url === AUTH_REFRESH_API_PATH;

export const initRefreshInterceptor = (axios) => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            const status = get(error, 'request.status');
            const url = get(error, 'config.url');

            if (shouldIntercept401Refresh(status, url)) auth.onRefreshTokenFailure();

            return Promise.reject(error);
        }
    );

    // Function that will be called to refresh authorization
    const refreshAuthLogic = (failedRequest) =>
        auth.refreshToken().then((tokenData) => {
            failedRequest.response.config.headers.Authorization = `Bearer ${tokenData.access_token}`; // eslint-disable-line
            return Promise.resolve();
        });

    // Instantiate the interceptor (you can chain it as it returns the axios instance)
    createAuthRefreshInterceptor(axios, refreshAuthLogic);
};
