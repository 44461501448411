import axios from '@/lib/axios/axios';
// import { MSW_URL } from '../../config/config';

export const AUTH_API_PATH = '/auth';
export const AUTH_TOKEN_API_PATH = '/auth/token';
export const AUTH_REFRESH_API_PATH = '/auth/refresh';
export const AUTH_FORGOTTEN_PASSWORD_API_PATH = '/auth/request-reset';
export const AUTH_PASSWORD_RESET_API_PATH = '/auth/reset';

export default {
    /**
     * Gets a new user token.
     * @param {object} postData - Data sent to get a new token.
     */
    getToken: async (postData) => axios.post(AUTH_TOKEN_API_PATH, postData),

    /**
     * This will keep a user logged in, refreshing the access token by using the refresh token.
     * @param {string} refreshToken - The refresh token to be sent.
     */
    refreshToken: async (refreshToken) => axios.post(AUTH_REFRESH_API_PATH, { refresh_token: refreshToken }),

    /**
     * This will send a password reset notification to the supplied user.
     * @param {object} postData - Object containing "email" key.
     */
    forgottenPassword: async (postData) => axios.post(AUTH_FORGOTTEN_PASSWORD_API_PATH, postData),

    /**
     * This will reset the password of the matching email / token combination.
     * @param {object} postData - Object containing "token", "email", "password" and "confirm" keys.
     */
    resetPassword: async (postData) => axios.post(AUTH_PASSWORD_RESET_API_PATH, postData),

    /**
     * Logout the user and invalidate the tokens on the api side
     */
    logout: async (postData) => axios.delete('/auth', postData),
};
