/* eslint-disable camelcase */
import get from 'lodash.get';

import { affiliateInScope } from '../../../../../affiliate/helpers/affiliateInScope';
import productTypes from '../../../../../../types/api/products/ProductTypes';
import OrderItem from '../../../../../../types/api/order/OrderItem';
import getPatientType from '../../../../../auth/helpers/getPatientType';

type AffiliateCodeType = string | null;

export interface FormatProductsReturnType {
    name: string;
    variant: string;
    id: number;
    category: string;
    price: string;
    price_total: string;
    quantity: number;
    dimension2: 'Logged in';
    dimension3: productTypes;
    dimension4: 'Default Variant' | 'Not Default Variant';
    dimension15: string | null;
    boltOn: boolean;
    patientType: OrderItem['patientType'];
    recChoice: OrderItem['recChoice'];
}

// eslint-disable-next-line arrow-body-style
const formatProducts = (orderItems: OrderItem[], affiliateCode: AffiliateCodeType): FormatProductsReturnType[] => {
    return orderItems.map((item) => {
        const { treatment, reference, quantity, type, unit_cost, total, vat, recChoice } = item; // eslint-disable-line object-curly-newline

        const name = get(item, 'parent.name') || treatment;

        const isDefault = !item.parent || item.parent.default_child === reference ? 'Default Variant' : 'Not Default Variant';

        const condition = item.condition || 'General Health';

        const itemUnitCost = ((unit_cost || 0) / 100).toFixed(2);
        const itemTotal = ((total || 0) / 100).toFixed(2);

        return {
            name,
            variant: treatment,
            id: reference,
            category: condition,
            price: itemUnitCost,
            price_total: itemTotal,
            quantity,
            dimension2: 'Logged in',
            dimension3: type.toUpperCase() as productTypes,
            dimension4: isDefault,

            dimension15: affiliateInScope(reference) ? affiliateCode : null, // If there is an affiliate code applied.
            ...(item.stock_cost && item.stock_cost > 0 ? { metric1: Number(((item.stock_cost / 100) * quantity).toFixed(2)) } : {}),
            netItemRev: ((total - vat) / 100).toFixed(2),
            boltOn: !!item.bolt_on,
            patientType: getPatientType(), // Existing baskets may not have this data!
            recChoice: recChoice || null, // Existing baskets may not have this data!
        };
    });
};

export default formatProducts;
