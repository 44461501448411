export enum gaPaymentTypes {
    NewCard = 'New Card',
    SavedCard = 'Saved Card',
    ApplePay = 'Apple Pay',
    Gpay = 'GPay',
}

interface returnType {
    paymentType: 'New Card' | 'Saved Card' | 'Apple Pay' | 'GPay';
}

/**
 * Decodes the user UUID from the auth token sent from the API.
 * @param {authTokenInterface} authToken - Token recieved from login request.
 * @returns {returnType}
 */
const formatPaymentSelectAnalytics = (paymentType: gaPaymentTypes): returnType => ({
    paymentType,
});

export default formatPaymentSelectAnalytics;
