import get from 'lodash.get';
import set from 'lodash.set';

const sentryStatusCaptures = [422];

export const initSentryInterceptor = (axios) => {
    /** Report errors and information to sentry from the statuses above */
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            const status = get(error, 'request.status');

            let response;

            try {
                response = JSON.parse(error.request.response);
            } catch (e) {} // eslint-disable-line no-empty

            if (sentryStatusCaptures.indexOf(status) > -1 && window.Sentry) {
                const errorJSON = error.toJSON();
                const url = get(errorJSON, 'config.url');
                const resMessage = get(response, 'message');
                const resData = get(response, 'data');
                const resErrors = get(response, 'errors');
                let reqPostData = get(errorJSON, 'config.data');

                // eslint-disable-next-line no-undef
                Sentry.withScope((scope) => {
                    if (errorJSON) scope.setExtra('Error Information', errorJSON);
                    if (resData) scope.setExtra('Response Data', resData);
                    if (resErrors) scope.setExtra('Response Errors', resErrors);
                    if (reqPostData) {
                        reqPostData = JSON.parse(get(errorJSON, 'config.data'));
                        if (get(reqPostData, 'current')) set(reqPostData, 'current', "['redacted']"); // redact passwords
                        if (get(reqPostData, 'confirm')) set(reqPostData, 'confirm', "['redacted']"); // redact passwords
                        scope.setExtra('Post Data', reqPostData);
                    }

                    // eslint-disable-next-line no-undef
                    Sentry.captureMessage(`422: on ${url} ${resMessage ? ` - ${resMessage}` : ''}`);
                });
            }

            return Promise.reject(error);
        }
    );
};
