import { localStorageSet, localStorageGet, localStorageRemove } from '@/helpers/localStorage';

export const MASTER_ANSWER_LOOKUP_KEY = 'MASTER_QUESTION_LOOKUP';
export const MASTER_ANSWER_LOOKUP_KEY_LAST_INTERACTION = 'MASTER_QUESTION_LOOKUP_TIME';

const timeToKeepAlive = 60; // mins

/**
 * In a session, every time a user answers a question, it ends up in here. To save people answering questions again in a session.
 */
class MasterAnswersLookup {
    answers = localStorageGet(MASTER_ANSWER_LOOKUP_KEY) || {};

    setAnswer = (id, answer) => {
        this.answers[id] = answer;
        localStorageSet(MASTER_ANSWER_LOOKUP_KEY, this.answers);
        localStorageSet(MASTER_ANSWER_LOOKUP_KEY_LAST_INTERACTION, new Date().getTime());
    };

    getAnswer = (id) => this.answers[id];

    getAllAnswers = () => this.answers;

    checkDate = () => {
        const lastInteraction = localStorageGet(MASTER_ANSWER_LOOKUP_KEY_LAST_INTERACTION);
        let deleteThis = true;

        try {
            if (parseInt(lastInteraction) < new Date().getTime() - timeToKeepAlive * 60000) {
                deleteThis = true;
            } else {
                deleteThis = false;
            }
        } catch (e) {
            //
        }

        if (deleteThis) this.clearStorage();
    };

    clearStorage = () => {
        localStorageRemove(MASTER_ANSWER_LOOKUP_KEY);
        localStorageRemove(MASTER_ANSWER_LOOKUP_KEY_LAST_INTERACTION);

        this.answers = {};
    };
}

const masterAnswersLookup = new MasterAnswersLookup();
masterAnswersLookup.checkDate();

export default masterAnswersLookup;
