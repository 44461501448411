import queryString from 'query-string';

interface QueryParams {
    [key: string]: string;
}

export const stripQueryStringParams = <T extends QueryParams, >(searchParams: QueryParams, regex = /[^a-zA-Z0-9-\s.,]/g): T => {
    const saveSearchParams: QueryParams = {};

    Object.keys(searchParams).forEach((k) => {
        if (searchParams[k]) saveSearchParams[k] = searchParams[k].replace(regex, '');
    });

    return (saveSearchParams || {}) as T;
}

/**
 *
 * @param {*} regex
 * @returns {{[key: string]: string}} - key value
 */
export const getQueryStringObj = (regex = /[^a-zA-Z0-9-\s.,]/g): QueryParams  => {
    const inBrowser = typeof document !== 'undefined';

    if (!inBrowser) {
        return {};
    }

    const searchParams = document.location.search ? queryString.parse(document.location.search) : {};

    const saveSearchParams: QueryParams = {};

    Object.keys(searchParams).forEach((k) => {
        if (searchParams[k]) saveSearchParams[k] = (searchParams[k] as string)?.replace(regex, '');
    });

    // Strip the string of everything exept numbers, letters and strings.
    return saveSearchParams || {};
};

const getQueryString = (key: string) => {
    const inBrowser = typeof document !== 'undefined';

    if (!inBrowser) {
        return undefined;
    }

    const searchParams = getQueryStringObj();

    // Strip the string of everything exept numbers, letters and strings.
    return searchParams[key] ? searchParams[key] as string : undefined;
};

export default getQueryString;
