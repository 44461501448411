/**
 * Checks if localStorage is available in the current browser.
 * @returns {boolean}
 */
export const isLocalStorageAvailable = (() => {
    // If there is now browser window.
    if (typeof window === 'undefined') {
        return false;
    }

    // Try to add and remove an item.
    try {
        // Get the storage from the window.
        const storage = window.localStorage;

        const storageTest = '__storage_test__';
        storage.setItem(storageTest, storageTest);
        storage.removeItem(storageTest);
        return true;
    } catch (e) {
        return false;
    }
})();


/**
 * Remove local storage by key
 * @param {string} key - key of the local storage to remove
 */
export const localStorageRemove = (key) => {
    // If localStorage is not available.
    if (isLocalStorageAvailable === false) {
        return;
    }

    window.localStorage.removeItem(key);

    dispatchEvent(new Event('storage')); // As the storage event only fires on other tabs changing it we're adding this here
};

/**
 * Set local storage key with value
 * @param {*} key - key to set
 * @param {*} value - value/ object etc to stringify and set
 * @returns any
 */
export const localStorageSet = (key, value) => {
    // If localStorage is not available.
    if (isLocalStorageAvailable === false) {
        return;
    }

    if (typeof value === 'undefined' || value === 'undefined' || value === null || value === 'null') {
        localStorageRemove(key);
        return;
    }

    window.localStorage.setItem(key, JSON.stringify(value));

    dispatchEvent(new Event('storage')); // As the storage event only fires on other tabs changing it we're adding this here
};

/**
 * Get local storage value by key
 * @param {*} key - key to retrieve
 */
export const localStorageGet = (key) => {
    // If localStorage is not available.
    if (isLocalStorageAvailable === false) {
        return null;
    }

    const value = window.localStorage.getItem(key);
    try {
        return value ? JSON.parse(value) : null;
    } catch {
        //
    }
    return value;
};

/**
 * Resets all local storage and clears it all.
 */
export const localStorageClearAll = () => {
    // If localStorage is not available.
    if (isLocalStorageAvailable === false) {
        return;
    }

    window.localStorage.clear();
};

/**
 * Removes local storage items via keys in array paramater.
 * @param {array} items - Array of local storage keys.
 */
export const localStorageClearItems = (items = []) => {
    // If no items were provided to clear.
    if (!items.length) {
        return;
    }

    items.forEach((key) => {
        localStorageRemove(key);
    });
};

