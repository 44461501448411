import { isBrowser } from '../../../../config/config';

interface returnType {
    url: string;
    searchTerm: string;
    currentPageOfResults: number;
}

/**
 * Formats the search analytics data to be sent to Google Analytics.
 * @param searchTerm - The search term used to search for a product.
 * @param currentPageOfResults - The page of results the user is currently on.
 * @returns - An object containing the url, search term and the page of results the user is currently on.
 */
const formatSearchAnalytics = (searchTerm: string, currentPageOfResults: number): returnType => ({
    url: isBrowser() ? window.location.pathname : 'Not available',
    searchTerm,
    currentPageOfResults,
});

export default formatSearchAnalytics;
