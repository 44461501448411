import get from 'lodash.get';

import { affiliateInScope } from '../../../affiliate/helpers/affiliateInScope';
import { getAffiliateJwtDecoded } from '../../../affiliate/helpers/getAffiliateJwt';
import ProductTypes from '../../../../types/api/products/ProductTypes';
import ConsultationTypes from '../../../../types/api/Consultation/ConsultationTypes';
import { ConsultationAnalyticsData, ConsultationMeta } from '../../../../app/(consultation)/[dynamicRoute]/consultation/_components/Context/ConsultationDataContext';

interface AnalyticsData extends Pick<ConsultationAnalyticsData, 'patientType' | 'consultationCategory'> {
    productName: string | 'Undefined';
    productId: string | number | 'Undefined';
    productCategory: string;
    productVariant: string | 'Undefined';
    consultationType: ProductTypes | 'Undefined';
    affiliateCode: string | 'Undefined';
    loggedInStatus: 'Logged in' | 'Not Logged in';
    'Reorder Status': ConsultationTypes;
    recChoice?: boolean | null;
}

/**
 * Formats data from the consultation to be sent to google analytics.
 * @param {Treatment} treatment - Treatment data stored in the consultation.
 * @param {boolean} isLoggedIn - If there is a patient logged in when completing the consultation.
 * @param {ConsultationTypes} orderType - NEW, EXPIRED, REFILL.
 * @param {ConsultationAnalyticsData} consultationAnalyticsData
 * @returns {ReturnType}
 */
const formatConsultationAnalytics = (
    consultationMeta: ConsultationMeta,
    isLoggedIn: boolean,
    orderType: ConsultationTypes,
    consultationAnalyticsData: ConsultationAnalyticsData
): AnalyticsData => {
    const {
        patientType,
        consultationCategory,
        recChoice,
        initialTreatmentData
    } = consultationAnalyticsData;

    // Create universal analytics data.
    const analyticsData = {
        loggedInStatus: isLoggedIn ? 'Logged in' : 'Not Logged in',
        'Reorder Status': orderType,
        patientType,
        consultationCategory,
        recChoice,
    } as Pick<AnalyticsData, 'loggedInStatus' | 'Reorder Status' | 'patientType' | 'recChoice' | 'consultationCategory'>;

    // If there is a treatment in the main consultation data.
    if ('selectedVariant' in consultationMeta || 'selectedTreatment' in consultationMeta) {
        let productName = consultationMeta?.selectedTreatment?.name || 'Undefined';

        if (consultationMeta?.selectedTreatment?.parent !== 0 && consultationMeta?.selectedTreatment?.parent?.name) {
            productName = consultationMeta?.selectedTreatment?.parent?.name;
        }

        const affiliateCode = (consultationMeta.selectedVariant && affiliateInScope(consultationMeta.selectedVariant.id))
            ? get(getAffiliateJwtDecoded({}), 'affiliate_code', 'Undefined')
            : 'Undefined'

        return {
            ...analyticsData,
            productName,
            productId: consultationMeta.selectedVariant?.id || 'Undefined',
            productCategory: consultationMeta.selectedTreatment?.condition_name || 'General Health',
            productVariant: consultationMeta.selectedVariant?.name || 'Undefined',
            consultationType: consultationMeta.selectedVariant?.type.toUpperCase() as ProductTypes || 'Undefined',
            affiliateCode,
        };
    }

    // If there is no specific consultation data, fallback to the initial analytics data or 'Undefined' values.
    let productName = initialTreatmentData?.selectedTreatment?.name || 'Undefined';

    if (initialTreatmentData?.selectedTreatment?.parent !== 0 && initialTreatmentData?.selectedTreatment?.parent?.name) {
        productName = initialTreatmentData?.selectedTreatment?.parent?.name;
    }

    const affiliateCode = (initialTreatmentData?.selectedVariant && affiliateInScope(initialTreatmentData.selectedVariant.id))
        ? get(getAffiliateJwtDecoded({}), 'affiliate_code', 'Undefined')
        : 'Undefined';

    return {
        ...analyticsData,
        productName,
        productId: initialTreatmentData?.selectedVariant?.id || 'Undefined',
        productCategory: initialTreatmentData?.selectedTreatment?.condition_name || 'General Health',
        productVariant: initialTreatmentData?.selectedVariant?.name || 'Undefined',
        consultationType: initialTreatmentData?.selectedVariant?.type.toUpperCase() as ProductTypes || 'Undefined',
        affiliateCode,
    };
};

export default formatConsultationAnalytics;
