"use client";

import { localStorageGet } from "../../../helpers/localStorage";
import { checkLoggedIn } from "./checkLoggedIn";

export const PATIENT_TYPE_LOCAL_STORAGE_KEY = 'patientType';

export type PatientType = 'NEW' | 'RETURNING';
type PatientTypeLocalStorage = 'NEW' | 'RETURNING' | null;

/** This is to get patient type for analytics purposed to know if someone is a new or returning customer. */
export default function getPatientType(): PatientType {
    if (!checkLoggedIn()) {
        return 'NEW';
    }

    // check to see if they have ever had a fulfilled order before.
    return (localStorageGet(PATIENT_TYPE_LOCAL_STORAGE_KEY) as PatientTypeLocalStorage) || 'NEW';
};
