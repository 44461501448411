/* eslint-disable camelcase */
import get from 'lodash.get';

import { affiliateInScope } from '../../../affiliate/helpers/affiliateInScope';
import { getAffiliateJwtDecoded } from '../../../affiliate/helpers/getAffiliateJwt';
import ProductTypes from '../../../../types/api/products/ProductTypes';
import { BasketItem } from '../../../../types/api/basket/BasketItem';
import ConsultationTypes from '../../../../types/api/Consultation/ConsultationTypes';
import { AddToBasketData } from '../../../../data/context/basketContext/hooks/useManageBasketItems';

interface CustomBasketItem extends BasketItem {
    description: string | null;
    image: string | null;
}

interface ReturnType {
    ecommerce: {
        add: {
            products: [
                {
                    name: string | null;
                    id: number;
                    category: string;
                    quantity: number;
                    price: string;
                    variant: string;
                    dimension2: 'Logged in';
                    dimension3: ProductTypes;
                    dimension4: 'Default Variant' | 'Not Default Variant';
                    dimension5: ConsultationTypes;
                    dimension15: string | null;
                    boltOn: boolean;
                    patientType: AddToBasketData['patientType'] | null,
                    recChoice: AddToBasketData['recChoice'];
                }
            ];
        };
    };
    freshDataLayer: {
        treatment: string;
        desc: string | null;
        image: string | null;
        name: string;
        id: number;
        quantity: number;
        wasPrice: string;
        nowPrice: string;
        sku: number;
        stock: number | null;
        url: string;
    };
}

/**
 * Formats add to basket data for analytics.
 * @param {BasketItem} basketItem - The item added to the basket.
 * @returns {returnType}
 */
const formatAddToBasketAnalytics = (
    basketItem: CustomBasketItem,
): ReturnType => {
    const {
        total,
        is_default_variant,
        reference,
        condition_name,
        quantity,
        treatment,
        unit_cost,
        type,
        order_type,
        image,
        description
    } = basketItem;

    const formattedPrice = (total / 100).toFixed(2);
    const isDefaultVariant = is_default_variant ? 'Default Variant' : 'Not Default Variant';

    const formattedReference = typeof reference === 'string' ? parseInt(reference, 10) : reference;

    return {
        ecommerce: {
            add: {
                products: [
                    {
                        name: get(basketItem, 'product_name') || null, // Treatment name
                        id: formattedReference, // Variant id/sku
                        category: condition_name, // Treatment condition
                        quantity, // Variant quantity
                        price: ((unit_cost || 0) / 100).toFixed(2), // Variant unit cost
                        variant: treatment, // Variant name
                        dimension2: 'Logged in', // Logged in status
                        dimension3: type.toUpperCase() as ProductTypes, // PRODUCT, PMED, ODONLY
                        dimension4: isDefaultVariant, // Was the default variant selected
                        dimension5: order_type, // Reorder or first time order
                        dimension15: affiliateInScope(formattedReference) ? get(getAffiliateJwtDecoded({}), 'affiliate_code', null) : null, // If there is an affiliate code applied
                        boltOn: !!get(basketItem, 'boltOn'),
                        patientType: get(basketItem, 'patientType', null), // Should always be set but just in case
                        recChoice: get(basketItem, 'recChoice', null), // Should always be set but just in case
                    }
                ]
            }
        },
        freshDataLayer: {
            treatment: condition_name, // Treatment condition
            desc: description, // Variant description
            image, // Variant image
            name: treatment, // Variant name
            id: formattedReference, // Variant id/sku
            quantity, // Variant quantity
            wasPrice: formattedPrice, // Variant price
            nowPrice: formattedPrice, // Variant price
            sku: formattedReference, // Variant id/sku
            stock: get(basketItem, 'stock') || null, // Variant stock
            url: window.location.href,
        },
    };
};

export default formatAddToBasketAnalytics;
